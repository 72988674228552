import { useState, useEffect, useRef, forwardRef } from 'react';
import { useSpring, animated } from 'react-spring';
import {
  SiteLink,
  OptionalSiteLinkWrapper,
  Image as SiteImage,
  Swiper,
  VideoPlayer,
} from 'components/shared';
import { gtmClickElementIds, trackEvents, trackInWindow } from 'hooks/tracking/track';
import renderLines from 'components/shared/renderLines';
import { getLinkHref } from 'lib/utils/helpers';
import { useInView, useUI } from 'hooks';
import springConfig from 'lib/utils/springConfigs';
import validateRequired from './validate-required';
import withPropValidation from './with-props-validation.tsx';

const AdaptiveSwiper = ({ children }) => (
  <>
    <Swiper
      className="relative w-full md:hidden"
      slidesPerView={1.2}
      spaceBetween={10}
      slidesOffsetBefore={10}
      slidesOffsetAfter={10}
    >
      {children}
    </Swiper>
    <Swiper
      className="relative hidden w-full md:block"
      slidesPerView={3}
      spaceBetween={15}
      slidesOffsetBefore={15}
      slidesOffsetAfter={15}
    >
      {children}
    </Swiper>
  </>
);

const BannersCarousel = ({ isDesktop, banners }) => {
  const getImageValue = ({ image, vimeoUrl, mobileImage, mobileVimeoUrl }) =>
    !vimeoUrl && !mobileVimeoUrl && isDesktop ? image || mobileImage : mobileImage || image;
  return (
    <AdaptiveSwiper>
      {banners
        .filter(({ link }) => !!link)
        .map(({ sys, image, mobileImage, vimeoUrl, mobileVimeoUrl, link }) => {
          const imageValue = getImageValue({ image, mobileImage, vimeoUrl, mobileVimeoUrl });
          const vimeoUrlValue = isDesktop ? vimeoUrl || mobileVimeoUrl : mobileVimeoUrl || vimeoUrl;
          return (
            <SiteLink
              key={sys?.id}
              href={getLinkHref(link.link)}
              external={link.externalLink}
              className="relative"
              id={`${gtmClickElementIds.sectionBanner} ~ ${link.name}`}
            >
              {imageValue && (
                <SiteImage
                  sizes="(max-width: 767px) 70vw, (min-width: 768px) 25vw"
                  src={imageValue}
                  layout="responsive"
                  className="pointer-events-none"
                />
              )}
              {vimeoUrlValue && (
                <VideoPlayer
                  videoURL={vimeoUrlValue}
                  className="pointer-events-none object-cover"
                  hasMuteButton={false}
                />
              )}
              <p className="pointer-events-none mt-[20px] text-center font-nimbus-sans-extd-d text-[12px] uppercase leading-[110%] underline">
                {link.text}
              </p>
            </SiteLink>
          );
        })}
    </AdaptiveSwiper>
  );
};

const Column = ({ children, showFrame }) => (
  <div className={`flex flex-1 flex-col ${showFrame ? 'gap-[15px]' : ''}`}>{children}</div>
);

const BannerContainer = forwardRef(
  (
    { children, showFrame, fullHeight, triggerAnimation, onMouseOver, onMouseLeave, shouldAnimate },
    ref
  ) => {
    const containerStyle = useSpring({
      transform: triggerAnimation || !shouldAnimate ? 'scale(1)' : 'scale(0.97)',
      config: {
        tension: 388,
        friction: 86,
        velocity: 0.019,
      },
      delay: 200,
    });

    return (
      <animated.div
        style={containerStyle}
        className={`
          relative overflow-hidden md:flex-1
          ${showFrame && fullHeight && ' gap-[15px]'}
          ${fullHeight ? ' h-[414px] md:h-auto' : ' h-[322px] md:h-[50%]'}
          `}
        ref={ref}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </animated.div>
    );
  }
);

const BannerContent = ({
  isDesktop,
  image,
  mobileImage,
  hoverImage,
  mobileHoverImage,
  subtitle,
  title,
  link,
  vimeoUrl,
  mobileVimeoUrl,
  triggerAnimation,
  hoverAnimation,
  shouldAnimate,
}) => {
  const subtitleStyle = useSpring({
    opacity: triggerAnimation || !shouldAnimate ? 1 : 0,
    config: springConfig.easeInButBetter,
    delay: 500,
  });
  const titleStyle = useSpring({
    transform: triggerAnimation || !shouldAnimate ? 'translateY(0%)' : 'translateY(100%)',
    config: springConfig.easeInButBetter,
    delay: 500,
  });

  const imageStyle = useSpring({
    transform: hoverAnimation ? 'scale(1)' : 'scale(1.03)',
    opacity: hoverAnimation && shouldAnimate ? 1 : 0,
    config: springConfig.easeInButStickier,
  });

  const imageSizes = '(max-width: 767px) 85vw, (min-width: 768px) 45vw';

  const imageValue =
    !vimeoUrl && !mobileVimeoUrl && isDesktop ? image || mobileImage : mobileImage || image;
  const hoverImageValue =
    !vimeoUrl && !mobileVimeoUrl && isDesktop
      ? hoverImage || mobileHoverImage
      : mobileHoverImage || hoverImage;
  const vimeoUrlValue = isDesktop ? vimeoUrl : mobileVimeoUrl;

  return (
    <>
      {imageValue && (
        <SiteImage sizes={imageSizes} src={imageValue} className="absolute h-full w-full" />
      )}
      {hoverImageValue && (
        <animated.div style={imageStyle} className="absolute h-full w-full">
          <SiteImage sizes={imageSizes} src={hoverImageValue} className="absolute h-full w-full" />
        </animated.div>
      )}
      {vimeoUrlValue && (
        <VideoPlayer videoURL={vimeoUrlValue} className="h-full w-full" videoClassName="absolute" />
      )}
      <OptionalSiteLinkWrapper
        link={link}
        onClick={() => {
          trackInWindow({
            event: trackEvents.componentBannerClick,
            page_path: window.location.pathname,
            component_name: title,
          });
        }}
        id={`${gtmClickElementIds.sectionBanner} ~ ${link?.name}`}
      >
        <div className="absolute inset-0 flex h-full w-full flex-col items-center justify-center p-[10px] text-center text-white md:p-[20px]">
          {subtitle && (
            <animated.h2
              style={subtitleStyle}
              className="font-nimbus-sans-d text-[15px] capitalize"
            >
              {renderLines(subtitle)}
            </animated.h2>
          )}
          {title && (
            <h1 className="my-[12px] overflow-y-hidden font-nimbus-sans-extd-d text-[35px] font-bold uppercase leading-[85%]">
              <animated.div style={titleStyle}>{renderLines(title)}</animated.div>
            </h1>
          )}
          {link && (
            <span className="font-nimbus-sans-extd-d text-[12px] uppercase underline">
              {link.text}
            </span>
          )}
        </div>
      </OptionalSiteLinkWrapper>
    </>
  );
};

const renderBanner =
  (showFrame, shouldAnimate, isDesktop) =>
  ({
    sys,
    image,
    mobileImage,
    hoverImage,
    mobileHoverImage,
    subtitle,
    title,
    link,
    fullHeight,
    vimeoUrl,
    mobileVimeoUrl,
  }) => {
    const bannerRef = useRef();
    const onScreen = useInView(bannerRef);
    const [triggerAnimation, setTriggerAnimation] = useState(false);
    const [bannerHovered, setBannerHovered] = useState(false);

    useEffect(() => {
      if (onScreen) {
        setTriggerAnimation(true);
      } else setTriggerAnimation(false);
    }, [onScreen]);

    return (
      <BannerContainer
        key={sys?.id}
        showFrame={showFrame}
        fullHeight={fullHeight}
        triggerAnimation={triggerAnimation}
        onMouseOver={() => setBannerHovered(true)}
        onMouseLeave={() => setBannerHovered(false)}
        ref={bannerRef}
        shouldAnimate={shouldAnimate}
      >
        <BannerContent
          isDesktop={isDesktop}
          image={image}
          mobileImage={mobileImage}
          hoverImage={hoverImage}
          mobileHoverImage={mobileHoverImage}
          vimeoUrl={vimeoUrl}
          mobileVimeoUrl={mobileVimeoUrl}
          subtitle={subtitle}
          title={title}
          link={link}
          triggerAnimation={triggerAnimation}
          hoverAnimation={bannerHovered}
          shouldAnimate={shouldAnimate}
        />
      </BannerContainer>
    );
  };

const Section = ({
  data: { showFrame, showAsCarousel, bannersCollection, verticalMargins, animated: shouldAnimate },
}) => {
  const banners = bannersCollection.items.filter(Boolean);
  const { isDesktop } = useUI();
  const getFramePadding = () => {
    if (verticalMargins === 'Top') return 'px-[15px] pt-[15px]';
    if (verticalMargins === 'Bottom') return 'px-[15px] pb-[15px]';
    if (verticalMargins === 'None') return 'px-[15px]';
    // Top + Bottom
    return 'p-[15px]';
  };

  const framePadding = getFramePadding();

  if (showAsCarousel)
    return (
      <div className="pb-[30px]">
        <BannersCarousel isDesktop={isDesktop} banners={banners} />
      </div>
    );

  const orderedBanners = banners.map((item, idx) => ({ ...item, order: idx }));
  const fullHeightBanners = orderedBanners.filter(item => !!item.fullHeight);
  const halfHeightBanners = orderedBanners.filter(item => !item.fullHeight);

  const renderFullHeightBanners = () =>
    fullHeightBanners.map(renderBanner(showFrame, shouldAnimate, isDesktop));
  const renderHalfHeightBanners = key =>
    halfHeightBanners.length ? (
      <Column key={key} showFrame={showFrame}>
        {halfHeightBanners.map(renderBanner(showFrame, shouldAnimate, isDesktop))}
      </Column>
    ) : null;

  const fullHeightFirst = fullHeightBanners[0]?.order === 0;
  const renderFns = [renderHalfHeightBanners, renderFullHeightBanners];
  if (fullHeightFirst) renderFns.reverse();

  return (
    <div
      className={`relative flex w-full flex-col antialiased md:h-screen md:max-h-[1000px] md:min-h-[500px] md:flex-row ${
        showFrame ? `gap-[15px] ${framePadding}` : ''
      }`}
    >
      {renderFns.map((fn, i) => fn(i))}
    </div>
  );
};

export default withPropValidation(Section, ({ data: { name, bannersCollection } }) =>
  validateRequired({ Name: name, Banners: bannersCollection.items })
);
